<template>
  <div>
    <el-card style="min-height: 45rem">
    <div v-loading="loading" element-loading-text="加载中请稍候..." class="flex-def flex-zCenter flex-cCenter flex-zTopBottom" style="min-height: 20rem">
      <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom" style="margin-bottom: 2rem">
        <template v-if="qrcode">
          <img  :src="qrcode" style="height: 10rem;width: 10rem;" alt="">
          <div class="y-desc">小程序管理员识别二维码上传版本</div>
        </template>
        <template v-if="finished">
          <div style="font-size: 2rem;color: #3cc51f;font-weight: 600">上传成功</div>
          <div class="y-desc">请前往小程序官方后台发布版本</div>
        </template>
      </div>
      <template v-if="!finished">
        <div class="flex-def flex-cCenter" >
          <el-input :disabled="qrcode != ''" v-model="form.version" >
            <template #prepend>版本号</template>
            <template #append>
              <el-button type="primary" @click="openWs">发布版本</el-button>
            </template>
          </el-input>
        </div>
        <div class="y-desc" style="margin-top: 1rem">填写用于小程序官方后台的版本号例：1.1.1</div>
      </template>
      <div style="margin-top: 2rem;border: 1px solid #ededed;padding: 2rem">
        <div class="flex-def flex-cCenter">
          <div>
            <div class="flex-def flex-cCenter" style="margin-top: .5rem">
              <div class="y-desc" style="width: 6rem">AppId</div>
              <el-input v-model="judgeForm.app_id" size="mini" style="width: 15rem"></el-input>

            </div>
            <div class="flex-def flex-cCenter" style="margin-top: .5rem">
              <div class="y-desc" style="width: 6rem">AppSecret</div>
              <el-input v-model="judgeForm.app_secret" size="mini" style="width: 15rem"></el-input>
            </div>
          </div>
        </div>
        <div class="flex-def flex-zCenter" style="margin-top: 1rem">
          <el-button @click="edit" size="mini">确认修改</el-button>
        </div>
      </div>
    </div>
    </el-card>
  </div>
</template>

<script>
let ws = false;
export default {
  name: "wxapp",
  components:{
  },
  data(){
    return{
      form:{
        version:"1.0.0",
      },
      judgeForm:{
        app_id:"",
        app_secret:"",
      },
      qrcode:"",
      loading:false,
      finished:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.$u.api.shop.sys.wechatMini().then(res=>{
        this.judgeForm = res.info;
        if(res.info.last_version){
          this.form.version = res.info.last_version;
        }
      })
    },
    edit(){
      this.$u.api.shop.sys.wechatMiniEdit(this.judgeForm).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    openWs(){
      if (ws)return
      this.loading = true;
      this.$u.api.shop.sys.wechatMiniUploadCode(this.form).then(res=>{
        ws = new WebSocket(res);
        ws.onopen = function() {
          ws.send("go");
        };
        ws.onmessage = (evt)=> {
          res = JSON.parse(evt.data)
          if(typeof res !== "object" || res.errno !== 0){
            this.$message.error("请稍候重试:")
            console.log(res)
            ws.close();
            return
          }
          this.loading = false;
          if (res.message === "code"){
            this.qrcode = res.data;
            return;
          }
          if (res.message === "ok"){
            this.loading = true;
            ws.send("up")
            return;
          }
          if (res.message === "success"){
            this.finished = true;
          }
        };
        ws.onclose =()=>{
          this.qrcode = "";
          this.loading = false;
          ws = false;
        };
        ws.onerror = (e)=>{
          console.log(e);
          this.loading = false;
          this.$message.error("请稍候重试...")
          ws.close();
          ws = false;
        }
      })

    }
  }
}
</script>

<style scoped>
.y-desc{
  font-weight: 600;
  line-height: 1rem;
}
</style>